<template>
  <div class="projectData">
    <el-form :model="queryInfo" class="ly-header__bg" label-width="120px">
      <el-form-item label="发布日期：">
        <data-time @dateChange="dateClick" />
      </el-form-item>
    </el-form>

    <div class="ly-container__bg">
      <div class="ly_operation">
        <div class="left">
          <el-button
            type="primary"
            icon="jr-iconjr-icon-add"
            @click="addItem"
            style="margin-right: 0"
            >添加</el-button
          >
          <el-button @click="multiDelete">
            批量删除
            <i class="jr-iconjr-icon-delect" />
          </el-button>
          <popover @popoverClick="popoverClick" />
          <div class="upload_time">
            <div class="caretTime" @click="uploadTime" style="color: #606266">
              按上传时间
            </div>
            <i
              class="el-icon-caret-top"
              :class="[
                queryInfo.columnName == 'create_time' && queryInfo.order == 0
                  ? 'isActiveTime1'
                  : 'iconColor',
              ]"
              @click="caretTop"
            />
            <i
              class="el-icon-caret-bottom"
              :class="[
                queryInfo.columnName == 'create_time' && queryInfo.order == 1
                  ? 'isActiveTime1'
                  : 'iconColor',
              ]"
              @click="caretBottom"
            />
          </div>
        </div>
        <el-input
          placeholder="请输入图片名称和图片描述检索"
          v-model="queryInfo.queryConditions"
          class="lager-input"
          @change="search"
        >
          <template slot="append">
            <div @click="search">搜索</div>
          </template>
        </el-input>
      </div>
      <el-table
        class="ly_table"
        :data="projectTable"
        :header-cell-style="{
          background: '#F5F6F7',
          color: '#363E4D',
          'text-align': 'center',
        }"
        @selection-change="handleSelectionChange"
        :default-sort="{ prop: 'date', order: 'descending' }"
        @sort-change="sortChange"
        :cell-style="{ 'text-align': 'center' }"
        style="width: 100%"
      >
        <el-table-column type="selection" width="55" align="center" />
        <template slot="empty">
          <img src="@/assets/img/table.png" alt="没有数据" srcset="" />
        </template>
        <el-table-column label="序号" align="center" width="80">
          <template v-slot="scope">
            <span
              >{{
                (queryInfo.pageNum - 1) * queryInfo.pageSize +
                (scope.$index + 1)
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="reportImgUrl" label="报告图表" width="150">
          <template slot-scope="scope">
            <el-popover
              placement="right"
              trigger="hover"
              v-if="scope.row.reportImgUrl"
            >
              <img
                :src="scope.row.reportImgUrl"
                style="max-height: 1000px; max-width: 1000px"
              />
              <img
                slot="reference"
                :src="scope.row.reportImgUrl"
                style="max-height: 100px; max-width: 100px; cursor: pointer"
                @click="editItem(scope.row)"
              />
            </el-popover>
            <div @click="editItem(scope.row)" style="cursor: pointer" v-else>
              <el-image
                :src="require('../../../assets/img/pro.png')"
                class="headerImg"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="imgName" label="图片名称" align="center">
          <template v-slot="{ row }">
            <div @click="editItem(row)" style="cursor: pointer">
              <span>{{ row.imgName }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="imgDescribe" label="图片描述" align="center">
          <template slot-scope="scope">
            <div>
              <el-popover
                placement="right"
                width="300"
                trigger="hover"
                :content="scope.row.imgDescribe"
              >
                <div slot="reference">
                  <div
                    v-if="
                      scope.row.imgDescribe !== null &&
                      scope.row.imgDescribe !== ''
                    "
                  >
                    {{ scope.row.imgDescribe.substring(0, 20) }}
                    <span v-if="scope.row.imgDescribe.length > 20">...</span>
                  </div>
                </div>
              </el-popover>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="reportName"
          label="关联报告名称"
          align="center"
        />
        <el-table-column prop="publishDate" label="发布日期" align="center">
        </el-table-column>
        <el-table-column
          prop="createName"
          label="创建人"
          align="center"
          width="80"
        />
        <el-table-column
          prop="createTime"
          label="创建时间"
          align="center"
          width="100"
          :formatter="formatTime"
        />
        <el-table-column label="操作" width="140" align="center">
          <template v-slot="{ row }">
            <button-table
              content="下载"
              @buttonClick="downloadClick(row)"
              icon="jr-iconjr-icon-download"
            />
            <button-table
              @buttonClick="editItem(row)"
              content="编辑"
              icon="jr-iconjr-icon-edit"
            />
            <button-table
              @buttonClick="deleteItem(row)"
              content="删除"
              icon="jr-iconjr-icon-delect"
            />
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50, 100, 200]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import Popover from "@/components/PopoverSmall.vue";
// import DataTime from '@/components/DataTime.vue'
import ImportData from "@/components/ImportData.vue";
import DicTagButton from "@/components/DicTagButton.vue";
import DicRadioButton from "@/components/DicRadioButton.vue";
import ButtonTable from "@/components/ButtonTable.vue";
import DataTime from "../../CaseData/dataTime.vue";
import { pagination } from "@/mixins/pagination";
import DicCheckboxGroup from "@/components/DicCheckboxGroup.vue";
import { getReportImageList, deleteReportImageById } from "@/api/report";
import { exportData } from "../../../utils/tool";
import UploadFileTalent from "@/components/UploadFileTalent.vue";
import axios from "axios";
import { URL } from "../../../../config";
export default {
  name: "ProjectData",

  mixins: [pagination],

  components: {
    ImportData,
    DicTagButton,
    DicRadioButton,
    ButtonTable,
    DataTime,
    Popover,
    DicCheckboxGroup,
    UploadFileTalent,
  },

  data() {
    return {
      loading: false,
      dialogVisibleTalent: false,
      dataData17: [],
      // 查询
      queryInfo: {
        columnName: "update_time",
        // columnNames:["update_time"],
        pageSize: 10,
        pageNum: 1,
        queryConditions: "", // 搜索查询条件
        standardStatus: "", //活动类别
        jobStatus: "", //费用类别
        years: null, // 年
        month: null, // 月
        day: null, // 日
        size: "",
        id: [],
        activityField: [],
        order: 1, // 0正序 1倒叙
      },
      projectTable: [], // 表格数据
      delete: {
        id: [],
        multiId: [],
      },
      dialogType: "行业类别",
      dialogVisible: false,
      valueI: 0,
    };
  },
  created() {
    this.search();
  },
  computed: {
    Authorization() {
      return sessionStorage.getItem("token");
    },
  },
  methods: {
    fileClick() {
      this.search();
    },
    async search() {
      const res = await getReportImageList(this.queryInfo);

      if (res.code === 200) {
        this.projectTable = res.data.list;
        this.total = res.data.total;
        this.projectTable.forEach((el) => {
          if (el.dataIntegrity) {
            el.dataIntegrity = Number(el.dataIntegrity);
          } else {
            el.dataIntegrity = 0;
          }
        });
      }

      this.total = res.data.total;
    },
    searchCountry(val) {
      this.queryInfo.releaseAddressCountry = val;
      this.search();
    },
    searchProvince(val) {
      this.queryInfo.releaseAddressProvince = val;
      this.search();
    },
    searchCity(val) {
      if (val != "北京" && val != "天津" && val != "上海" && val != "重庆") {
        this.queryInfo.releaseAddressRegion = val;
        this.search();
      }
    },
    async uploadTime() {
      switch (this.valueI) {
        case 0:
          this.queryInfo.columnName = "create_time";
          this.queryInfo.order = 1;
          this.valueI++;
          break;
        case 1:
          this.queryInfo.columnName = "create_time";
          this.queryInfo.order = 0;
          this.valueI++;
          break;
        case 2:
          this.queryInfo.columnName = "update_time";
          this.queryInfo.order = 1;
          this.valueI = 0;
          break;
      }
      this.search();
    },
    dateClick(obj) {
      if (Number(obj.month) > 0 && Number(obj.month) < 10) {
        obj.month = "0" + obj.month;
      }
      console.log(obj);
      if (obj.year == "0") {
        this.queryInfo.years = "";
        this.queryInfo.month = "";
        this.queryInfo.day = "";
        this.search();
      } else if (obj.month == "0") {
        this.queryInfo.years = obj.year + "";
        this.queryInfo.month = "";
        this.queryInfo.day = "";
        this.search();
      } else if (obj.day == "0") {
        this.queryInfo.years = obj.year + "";
        this.queryInfo.month = obj.month + "";
        this.queryInfo.day = "";
        this.search();
      } else {
        this.queryInfo.years = obj.year + "";
        this.queryInfo.month = obj.month + "";
        this.queryInfo.day = obj.day + "";
        this.search();
      }
    },

    // 字符串格式化成数组
    formatString(val) {
      if (!val) {
        return [];
      } else if (!val.length) {
        return val;
      } else {
        return val.split(",");
      }
    },
    editItem(row) {
      // this.$router.push({
      //   name: 'RecruitInfo',
      //   params: {
      //     projectDataType: 1,
      //     id: row.id,
      //   },
      // })
      let text = this.$router.resolve({
        path: "/researchReport/reportPicturesInfo",
        query: {
          personType: 1,
          id: row.id,
        },
      });
      window.open(text.href, "_blank");
    },

    addItem() {
      this.$router.push({
        name: "ReportPicturesInfo",
        query: {
          personType: 0,
        },
      });
    },

    // 点击文字切换正倒序
    caretText() {
      this.queryInfo.order = !this.queryInfo.order;

      if (this.queryInfo.order) {
        this.queryInfo.order = 1;
      } else {
        this.queryInfo.order = 0;
      }

      this.search();
    },
    formatTime(row, column) {
      const date = new Date(row[column.property]);
      return (
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
      );
    },
    // 正序
    caretTop() {
      this.queryInfo.order = 0;

      this.search();
    },
    // 倒序
    caretBottom() {
      this.queryInfo.order = 1;

      this.search();
    },
    handleSelectionChange(val) {
      this.delete.multiId = val.map((item) => {
        return item.id;
      });
      this.queryInfo.ids = val.map((item) => {
        return item.id;
      });
    },
    _deleteProject(params) {
      this.$confirm("确认删除吗 ?", "提示")
        .then(async () => {
          const res = await deleteReportImageById(params);

          if (res.code === 200) {
            this.$message.success("删除成功");
            this.search();
          } else {
            this.$message.error("删除失败");
          }
        })
        .catch(() => {});
    },
    deleteItem(row) {
      this.delete.id.push(row.id);

      this._deleteProject(this.delete.id);
    },
    multiDelete() {
      if (this.delete.multiId.length) {
        this._deleteProject(this.delete.multiId);
      }
    },
    sortChange(column) {
      this.pageNum = 1;
      if (column.prop === "dataIntegrity") {
        this.queryInfo.columnName = "data_integrity";
        if (column.order === "ascending") {
          this.queryInfo.order = 0;
        } else if (column.order === "descending") {
          this.queryInfo.order = 1;
        } else {
          this.queryInfo.order = 1;
          this.queryInfo.columnName = "update_time";
        }
      }
      this.search();
    },
    // 批量导出
    popoverClick(val) {
      if (val === "all") {
        Object.assign(this.queryInfo, { ids: [], size: this.total });
      } else if (val == 0 && this.queryInfo.ids.length) {
        Object.assign(this.queryInfo, {
          ids: this.queryInfo.ids,
        });
      } else {
        Object.assign(this.queryInfo, { size: val });
      }

      exportData(
        this.queryInfo, // 后台需要的参数
        "/reportImage/uploadReportImage", // 路径
        "application/zip", // 下载的文件类型
        this.search, // 下载完之后调查询方法
        "报告图表.zip"
      );

      // this.queryInfo.id = []
    },
    //下载产品附件
    async downloadClick(row) {
      // const URL = 'http://192.168.0.6:8085/'

      if (row.reportImg) {
        // const fileNames = row.reportImg.substring(
        //   row.reportImg.lastIndexOf("/") + 1
        // );
        axios({
          method: "GET",
          url: `${URL}/public/downloadUrlFile`,
          params: { urlStr: row.reportImg },
          responseType: "blob",
          headers: {
            // 设置请求头
            Authorization: this.Authorization,
          },
        })
          .then((res) => {
            let blob = new Blob([res.data], {
              type: "application/msword",
            });
            let href = window.URL.createObjectURL(blob); //创建新的URL表示指定的blob对象
            const a = document.createElement("a"); //创建a标签
            a.style.display = "none";
            a.href = href; // 指定下载'链接
            a.download = row.imgName + ".jpg"; //指定下载文件名
            a.click(); //触发下载
            URL.revokeObjectURL(a.href); //释放URL对象
            document.body.removeChild(a); //释放标签
          })
          .catch((err) => {
            //this.$message.error('文件下载失败')
          });
      } else {
        this.$message("该条数据没有附件！");
      }
    },
  },
};
</script>
<style lang="less" scoped>
.projectData {
  /deep/ .el-input-group__append {
    background: #1e9fff;
    color: #fff;
    border: 1px solid #1e9fff;
  }
  .caretTime:hover {
    cursor: pointer;
  }
}
.isActiveTime1 {
  color: #1e9fff !important;
}
.iconColor {
  color: #c0c4cc !important;
}
/deep/ .filterInfo .el-form-item__content {
  padding: 0;
}
</style>
