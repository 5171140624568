<template>
  <div class="popover">
    <el-popover placement="bottom" width="100" trigger="hover">
      <ul class="popover_ul">
        <li v-for="item in list" :key="item.size" @click="btnClick(item.size)">{{item.title}}</li>
      </ul>
      <el-button slot="reference">
        批量导出
        <i class="el-icon-caret-bottom" />
      </el-button>
    </el-popover>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          title: '导出所选',
          size: 0,
        },
        {
          title: '导出前10条',
          size: 10,
        },
        {
          title: '导出前30条',
          size: 30,
        },
        {
          title: '导出前50条',
          size: 50,
        },
        {
          title: '导出前100条',
          size: 100,
        },
        {
          title: '导出全部',
          size: 'all',
        },
      ],
    }
  },
  methods: {
    btnClick(size) {
      this.$emit('popoverClick', size)
    },
  },
}
</script>

<style lang="less" scoped>
.popover {
  &_ul {
    li {
      line-height: 25px;
    }
    li:hover {
      color: #1e9fff;
      cursor: pointer;
    }
  }
}
.el-icon-caret-bottom {
  font-size: 14px;
}
</style>
